<template>
  <div>
    <b-table stacked :items="revenueData"></b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      'reportRevenue'
    ]),
    revenueData () {
      if (!this.reportRevenue.data) {
        return
      }
      let data = this.reportRevenue.data,
      obj = {},
      res = []
      obj.dateSubmitted = this.$moment(this.reportRevenue.dateSubmitted).format('YYYY-MM-DD')
      obj.for = this.reportRevenue.reptYr + '/' + this.reportRevenue.reptMo
      obj.by = this.reportRevenue.user.fullName
      for (let i in data) {
        obj[data[i].label] = (data[i].type === 'money' ? '$' : '') + data[i].value
      }
      obj.totalRevenue = '$' + this.reportRevenue.totalRevenue
      obj.royaltiesDue = '$' + this.reportRevenue.royalties
      obj.comments = this.reportRevenue.comments || '- no comments -'
      res.push(obj)
      return res
    }
  },
  methods: {
    ...mapActions([
      'getReportsRevenue'
    ]),
    doGetReportsRevenue () {
      this.getReportsRevenue({
        id: this.$route.params.rptId
      })
    }
  },
  mounted () {
    this.doGetReportsRevenue()
  }
}
</script>
